<template>
  <div class="columns no-margin">
    <div class="column no-padding is-full">
      <div class="card">
        <header class="card-header has-background-primary">
          <p class="card-header-title has-text-light">TAMBAH KELOMPOK</p>
        </header>
        <div class="card-content events-card">
          <b-field class="field-spacer"></b-field>
          <template v-if="errorMap.non_field_errors">
            <p class="help is-danger">{{ errorMap.non_field_errors }}</p>
            <b-field class="field-spacer"></b-field>
          </template>
          <b-field
            label="Angkatan"
            custom-class="is-small"
            :type="{'is-danger': errorMap.angkatan}"
            :message="errorMap.angkatan"
          >
            <generic-select
              v-model="kelompok.angkatan"
              apiPath="/programstudi/angkatan/options/"
              @input="validateInput('angkatan')"
            ></generic-select>
          </b-field>
          <b-field
            label="Tag"
            custom-class="is-small"
            :type="{'is-danger': errorMap.pspd_tags}"
            :message="errorMap.pspd_tags"
          >
            <b-taginput
              v-model="kelompok.pspd_tags"
              field="nama"
              icon="tag"
              :allow-new="true"
              @input="validateInput('pspd_tags')"
            ></b-taginput>
          </b-field>
          <b-field class="field-spacer"></b-field>
          <div class="buttons">
            <b-button class="is-primary has-text-weight-semibold" @click.stop="cancel">Batal</b-button>
            <b-button
              class="is-primary has-text-weight-semibold"
              :disabled="!isValid"
              @click.stop.prevent="saveObject"
            >Simpan</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import createUpdateMixin from "@/apps/core/mixins/createUpdateMixin";
import Kelompok from "../models/kelompokCreate.js";


export default {
  name: "KelompokCreate",
  props: ["headerText"],
  mixins: [createUpdateMixin],
  components: {
    GenericSelect: () => import("@/apps/core/components/GenericSelect.vue"),
  },
  data() {
    this.kelompokMdl = new Kelompok();
    this.objectMdl = this.kelompokMdl;  // alias yg digunakan di cuMixin
    return this.kelompokMdl.getObservables();
  },
  watch: {
    kelompok: {
      // kelompokMdl berubah, maka tombol simpan bisa aktif
      handler(newValue, oldValue) {
        if (!this.kelompokMdl.getEdited()) {
          this.setEdited(this.isCreate || !!oldValue.id);
        }
      },
      deep: true
    }
  }
};
</script>
