import ModelBase from "@/apps/core/models/modelBase.js";

const modelName = "kelompok";
const modelPath = `/anggota/tag/`;

class Kelompok extends ModelBase {
  constructor() {
    let model = {
      angkatan: null,
      pspd_tags: []
    };
    let requiredFields = ["angkatan", "pspd_tags"];
    super(modelName, model, requiredFields, [], modelPath);
  }
}

export default Kelompok;